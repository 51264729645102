<template>
  <header>
    <router-link to="/" class="header-logo"><img alt="Zwischentöne logo" src="../assets/zwischentoene.svg" height="50"></router-link>
    <nav>
      <ul>
        <li><router-link to="/">Folgen</router-link></li>
        <li><router-link to="/team">Team</router-link></li>
        <li><router-link to="/impressum">Impressum</router-link></li>
        <li><a target="_blank" href="https://das-journalismus-stipendium.de/datenschutzerklaerung/">Datenschutz</a></li>
      </ul>
    </nav>
  </header>
</template>

<style lang="scss">
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 2rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
    background: white;

    .header-logo {
      margin-left: 50%;
      transform: translate(-50%,0);
    }
  }

  nav {
    display: none;

    @media (min-width: 600px) {
      display: block;
    }

    ul {
      list-style: none;
    }

    ul li {
      display: inline-flex;
      margin-left: 1rem;
    }
  }
</style>
