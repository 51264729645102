<template>
  <app-header />
  <router-view/>
  <app-footer />
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style lang="scss">
body {
  margin: 8px 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

nav {
  a {
    font-weight: bold;
    color: #663399;

    &.router-link-exact-active {
      color: #d74127;
    }
  }
}
</style>
