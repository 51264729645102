<template>
  <footer>
    <div>
      <Player ref="player" theme="dark" style="--vm-player-theme: #d74127; --vm-color-dark: #663399" :currentTime="currentTime" @vmPausedChange="onPausedChange">
        <Audio>
          <source :data-src="getSrc(0)" type="audio/mp3"/>
          <!-- <source> and <track> elements are placed here. -->
        </Audio>

        <DefaultUi no-controls>
          <Scrim></Scrim>

          <Controls full-width pin="center">
            <ControlSpacer />
            <PlaybackControl />
            <VolumeControl />
            <p>{{ files[track].name }}</p>
            <CurrentTime />
            <vue-wave-surfer :src="getSrc(0)" :options="options" ref="waveform" class="wavesurfer"></vue-wave-surfer>
            <EndTime />
            <button @click="isOpen = !isOpen">
              <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
            </button>
            <ControlSpacer />
          </Controls>
        </DefaultUi>
      </Player>

    </div>

    <div>
      <CollapseTransition>
        <ul v-show="isOpen">
          <li><button @click="loadFile(0)">Folge 1: Die Deutschrussin und der Ukraine-Krieg</button></li>
          <li><button @click="loadFile(1)">Folge 2: Der Sozialarbeiter und das Bildungssystem</button></li>
          <li><button @click="loadFile(2)">Folge 3: Der Bürgermeister und die Windräder</button></li>
          <li><button @click="loadFile(3)">Folge 4: Die Jüdin und die Regenbogenfahne</button></li>
          <li><button @click="loadFile(4)">Folge 5: Der Corona-Moderator und das Seuchenparlament</button></li>
        </ul>>
      </CollapseTransition>
    </div>
  </footer>
</template>

<script>
import { computed } from 'vue'
import { Player, Audio, DefaultUi, Scrim, Controls, PlaybackControl, VolumeControl, CurrentTime, EndTime, ControlSpacer } from '@vime/vue-next'
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'
import VueWaveSurfer from 'vue-wave-surfer/src/VueWaveSurfer'

export default {
  components: {
    Player,
    Audio,
    DefaultUi,
    Scrim,
    Controls,
    PlaybackControl,
    VolumeControl,
    CurrentTime,
    EndTime,
    ControlSpacer,
    CollapseTransition,
    VueWaveSurfer
  },

  mounted () {
    this.waveform.setMute(true)

    this.waveform.on('seek', (pos) => {
      this.currentTime = this.waveform.getDuration() * pos
    })

    this.mitt.on('loadFile', (track) => {
      this.loadFile(track)
    })
  },

  computed: {
    waveform () {
      return this.$refs.waveform.waveSurfer
    },
    player () {
      return this.$refs.player
    }
  },

  setup () {
    const year = computed(() => new Date().getFullYear())
    return { year }
  },

  data () {
    return {
      isOpen: false, // closed by default
      currentTime: 0,
      track: 0,
      options: {
        height: 60,
        cursorColor: '#fff',
        cursorWidth: 0,
        progressColor: '#d74127',
        waveColor: '#b89cd3'
      },
      files: [
        {
          name: 'Folge 1',
          file: '/folge1.mp3'
        },
        {
          name: 'Folge 2',
          file: '/folge2.mp3'
        },
        {
          name: 'Folge 3',
          file: '/folge3.mp3'
        },
        {
          name: 'Folge 4',
          file: '/folge4.mp3'
        },
        {
          name: 'Folge 5',
          file: '/folge5.mp3'
        }
      ]
    }
  },

  methods: {
    onPausedChange (e) {
      if (!e) {
        this.waveform.play()
        this.player.$el.addEventListener('vmPlaybackEnded', this.onPlaybackEnded)
      } else {
        this.waveform.pause()
      }
    },

    onPlaybackEnded () {
      this.player.$el.removeEventListener('vmPlaybackEnded', this.onPlaybackEnded)

      if (this.track < this.files.length - 1) {
        this.loadFile(this.track + 1)
        // this.player.$el.addEventListener('vmPlaybackReady', this.startPlay)
      }
    },

    getSrc () {
      return this.files[this.track].file
    },

    loadFile (no) {
      this.player.pause()
      this.waveform.container.classList.add('loading')

      setTimeout(() => {
        this.track = no
        this.waveform.load(this.getSrc())
        // this.player.$el.addEventListener('vmPlaybackReady', this.startPlay)
        this.waveform.on('ready', this.startPlay)
      }, 500)
    },

    startPlay () {
      this.waveform.container.classList.remove('loading')
      this.player.play()
      this.player.$el.removeEventListener('vmPlaybackReady', this.startPlay)
      this.player.$el.addEventListener('vmPlaybackEnded', this.onPlaybackEnded)
      this.waveform.un('ready', this.startPlay)
    }
  }
}
</script>

<style scoped lang="scss">
    @import '@vime/core/themes/default.css';

    footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: .5rem 1rem;
    background: #663399;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    p {
      margin-left: 1rem;
      margin-right: 1rem;
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      display: none;

      @media (min-width: 300px) {
        display: block;
      }
    }

    .loading {
      transform: rotateX(90deg);
    }

    button {
      display: none;
      border: none;
      background: transparent;
      color: #fff;
      font-size: 1.5rem;
      margin-left: 1rem;
      cursor: pointer;
      border-radius: 3px;
      padding: 4px 15px;
      transition: all 0.5s;

      &:hover,
      &:focus {
        background-color: #d74127;
        outline: 0;
      }

      @media (min-width: 300px) {
        display: block;
      }
    }

    li {
      text-align: center;
    }

    li button {
      background: #7e69ac;
      width: 90%;
      font-size: 1rem;
      //text-transform: uppercase;
      margin-bottom: 0.5rem;
      padding: 0.5rem;
      margin-left: auto;
      margin-right: auto;

      @media (min-width: 768px) {
        width: 60%;
      }
    }

    .wavesurfer {
      display: none;
      width:50%;
      transition: all 0.3s;
      cursor:crosshair;

      @media (min-width: 768px) {
        display: block;
      }
    }

    vm-end-time {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }

    vm-volume-control {
      display: none;

      @media (min-width: 300px) {
        display: block;
      }
    }
  }
</style>
